<template>
  <div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">上报信息</h4>
      <Table
        stripe
        size="small"
        :data="reportArray"
        :columns="reportColumns"
      ></Table>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">任务统计</h4>
      <Table
        stripe
        size="small"
        :data="taskArray"
        :columns="taskColumns"
      ></Table>
    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">设备状态统计</h4>
      <Table
        stripe
        size="small"
        :data="deviceStatusData"
        :columns="deviceStatusColumns"
      ></Table>
    </div>
  </div>
</template>

<script>
// import { getTerminalStatistic } from '@/api/product/resource'
import { getTaskStatistical } from '@/api/msp/device'

export default {
  data () {
    return {
      assetList: [],
      taskArray: [],
      reportCategory: null,
      // deviceStatusData: [],
      reportColumns: [
        { title: '类型', key: 'signName' },
        {
          title: '数量',
          align: 'right',
          key: 'quantity',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.handleViewRepTroubleSvg(params.row)
                }
              }
            }, params.row.quantity)
          }
        }
      ],
      taskColumns: [
        { title: '类型', key: 'typeName', width: 60 },
        {
          title: '总数/派单中/已派单/执行中/已完成',
          align: 'right',
          key: 'quantity',
          render: (h, params) => {
            return h('span', `${params.row.totalQuantity} / ${params.row.waittingQuantity} / ${params.row.assignedQuantity} / ${params.row.executingQuantity} / ${params.row.finishedQuantity}`)
          }
        }
      ],
      deviceStatusColumns: [
        { title: '状态', key: 'name' },
        { title: '数量', align: 'center', key: 'quantity' },
        {
          title: '占比',
          align: 'right',
          render: (h, params) => {
            return h('span', `${(params.row.ratio * 100).toFixed(2)}%`)
          }
        }
      ]
    }
  },
  created () {
    this.getTaskSta()
    // this.initTaskPlanData()
  },
  methods: {
    getTaskSta () {
      getTaskStatistical().then(res => {
        if (res && !res.errcode) {
          this.taskArray = res
        }
      })
    },
    /**
     * 查看上报故障SVG标注
     */
    handleViewRepTroubleSvg (params) {
      this.reportCategory = params.sign
      // 获取SVG标注的数据
      this.$store.dispatch('getTroubleReportStationData', { assetId: this.assetId, category: this.reportCategory })
      this.$store.commit('set_cur_category', this.reportCategory)
      this.$store.commit('set_tag_type', 1)
    }
  },
  computed: {
    deviceStatusData () {
      return this.$store.state.equipment.deviceStatusSta
    },
    reportArray () {
      return this.$store.state.equipment.deviceReportTroubleData
    },
    assetId () {
      return this.$store.state.equipment.selectedAssetId
    }
  }
}
</script>
